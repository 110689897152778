import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createSlots as _createSlots, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageTitleComponent = _resolveComponent("PageTitleComponent")!
  const _component_LinkIcon = _resolveComponent("LinkIcon")!
  const _component_ButtonComponent = _resolveComponent("ButtonComponent")!
  const _component_LinkComponent = _resolveComponent("LinkComponent")!
  const _component_GitHubIcon = _resolveComponent("GitHubIcon")!
  const _component_EditIcon = _resolveComponent("EditIcon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_PageActionsBarComponent = _resolveComponent("PageActionsBarComponent")!
  const _component_LoadingComponent = _resolveComponent("LoadingComponent")!
  const _component_UserMessageComponent = _resolveComponent("UserMessageComponent")!
  const _component_PageContentComponent = _resolveComponent("PageContentComponent")!

  return (_openBlock(), _createBlock(_component_PageContentComponent, { class: "project-view" }, {
    default: _withCtx(() => [
      _createVNode(_component_PageTitleComponent, {
        title: _ctx.project?.title ?? 'Loading Projects'
      }, null, 8, ["title"]),
      _createVNode(_component_PageActionsBarComponent, {
        returnLink: "/projects",
        returnText: "Return to Projects"
      }, _createSlots({ _: 2 }, [
        (_ctx.project !== null)
          ? {
              name: "right",
              fn: _withCtx(() => [
                (_ctx.project.viewUrl !== null)
                  ? (_openBlock(), _createBlock(_component_LinkComponent, {
                      key: 0,
                      href: _ctx.project.viewUrl
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ButtonComponent, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_LinkIcon)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["href"]))
                  : _createCommentVNode("", true),
                (_ctx.project.sourceCodeUrl !== null)
                  ? (_openBlock(), _createBlock(_component_LinkComponent, {
                      key: 1,
                      href: _ctx.project.sourceCodeUrl
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ButtonComponent, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_GitHubIcon)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["href"]))
                  : _createCommentVNode("", true),
                (_ctx.isAdmin)
                  ? (_openBlock(), _createBlock(_component_router_link, {
                      key: 2,
                      to: `/project/edit/${_ctx.project.reference}`
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ButtonComponent, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_EditIcon)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["to"]))
                  : _createCommentVNode("", true)
              ])
            }
          : undefined
      ]), 1024),
      (_ctx.isLoading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_LoadingComponent, { message: "Loading Project Details" })
          ]))
        : _createCommentVNode("", true),
      _createVNode(_component_UserMessageComponent, { details: _ctx.userMessage }, null, 8, ["details"]),
      (!_ctx.isLoading && !_ctx.userMessage.isVisible)
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            innerHTML: _ctx.markdown
          }, null, 8, _hoisted_2))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}