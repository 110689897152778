
import { computed, defineComponent, PropType } from 'vue';

import ProjectTagComponent from '@/view/projects/component/ProjectTag.component.vue';
import ButtonComponent from '@/component/Button.component.vue';
import LinkComponent from '@/component/Link.component.vue';
import ExternalLinkIcon from '@/component/icon/ExternalLinkIcon.component.vue';
import FolderIcon from '@/component/icon/FolderOpenIcon.component.vue';
import GitHubIcon from '@/component/icon/GitHubIcon.component.vue';

import { Project } from '@/model/Project.model';

export default defineComponent({
    name: 'ProjectItemComponent',

    components: {
        ProjectTagComponent,
        ButtonComponent,
        ExternalLinkIcon,
        FolderIcon,
        GitHubIcon,
        LinkComponent,
    },

    props: {
        project: {
            type: Object as PropType<Project>,
            required: true,
        },
    },

    setup(props) {
        const displayDate = computed<string>(() => props.project.startedAt.format('MMMM YYYY'));
        const url = computed<string>(() => `/project/${props.project.urlSlug}`);

        return {
            displayDate,
            url,
        }
    },
});
